import React, { useState, useContext } from 'react';
import { EuiAvatar, EuiButtonEmpty, EuiHeaderSectionItemButton, EuiPopover, EuiFlexGroup, EuiFlexItem, EuiText, EuiSpacer, EuiLink, useGeneratedHtmlId, EuiIcon } from '@elastic/eui';
import EditProfilePage from '../pages/EditProfilePage';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../Context';
import { PostOptions } from '../utils/RequestUtils';

const HeaderUserMenu = () => {
    const userPopoverId = useGeneratedHtmlId({ prefix: 'userPopover' });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const {user, setUser} = useContext(UserContext);
    // console.log(":menu", user);
    
    const logout = () => {
        sessionStorage.removeItem('user');
        setUser(null);
        fetch("/api/logout", PostOptions());
        // setLoginState(false);
        navigate('/');
    };

    const onMenuButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls={userPopoverId}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Account menu"
            onClick={onMenuButtonClick}
        >
            <EuiAvatar name={user?.username} size="m" />
        </EuiHeaderSectionItemButton>
    );

    return (
        <EuiPopover
            id={userPopoverId}
            repositionOnScroll
            button={button}
            isOpen={isOpen}
            anchorPosition="downRight"
            closePopover={closeMenu}
            panelPaddingSize="m"
        >
            <EuiFlexGroup gutterSize="m">
                <EuiFlexItem grow={false}>
                    <EuiAvatar name={user?.username} size="xl" />
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiText>
                                <p>{user?.username}</p>
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiIcon type={user.role === "teacher" ? "userAvatar" : "user"} size="m" />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="m" />

                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFlexGroup justifyContent="spaceBetween">
                                <EuiFlexItem grow={false}>
                                    <EuiButtonEmpty onClick={() => setIsModalVisible(true)}>Edit profile</EuiButtonEmpty >
                                </EuiFlexItem>

                                <EuiFlexItem grow={false}>
                                    <EuiButtonEmpty onClick={logout}>Log out</EuiButtonEmpty >
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EditProfilePage isVisible={isModalVisible} setIsVisible={setIsModalVisible} user={user}/>
        </EuiPopover>
    );
};

export default HeaderUserMenu;