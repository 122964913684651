// CourseList.js
import React, { useState, useContext } from 'react';
import {
    EuiCard,
    EuiContextMenu,
    EuiPopover,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiButton,
    EuiFieldSearch,
    EuiEmptyPrompt,
    EuiLoadingElastic,
    EuiPanel,
    EuiFlexGrid,
    useIsWithinBreakpoints,
    EuiButtonEmpty,
    EuiLoadingLogo,
    EuiTitle,
    EuiPage,
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Context';
import FileManagePage from './FileManagePage';
import Blocked from "../components/Blocked";
import JoinPage from "./JoinPage";
import RosterPage from "./RosterPage";
import StatisticChart from '../components/StatisticChart';
import { useParams } from 'react-router-dom';
import ChatPanel from '../components/ChatPanel';
import SessionListAll from '../components/SessionListAll';
import AgentList from '../components/AgentList';

const StatisticPage = () => {
    const { courseNumber, courseName } = useParams();
    const [agentName, setAgentName] = useState(null);
    const [agentConfig, setAgentConfig] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [allData, setAllData] = useState([]);

    if (!courseNumber) {
        return null;
    }
    const course = { course_name: courseName, course_number: courseNumber };

    return (
        <EuiPage restrictWidth={"80%"} style={{ overflowY: "hidden" }}>
            <EuiFlexGroup style={{ maxWidth: "100%" }} direction='column'>
                <EuiFlexItem>
                    <StatisticChart data={allData} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <div style={{ maxHeight: "60vh" }}>
                        <EuiFlexGroup gutterSize='none'>
                            <EuiFlexItem grow={1}>
                                <EuiFlexItem grow={1}>
                                    <EuiFlexGroup direction='column'>
                                        <EuiFlexItem justifyContent="center" alignItems="center" >
                                            <EuiTitle><h3>{courseNumber + " " + courseName}</h3></EuiTitle>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiFlexGroup gutterSize='xs'>
                                                <EuiFlexItem>
                                                    <AgentList
                                                        course={{ course_name: courseName, course_number: courseNumber }}
                                                        showCreate={false}
                                                        onSelect={(agent) => {
                                                            setAgentName(agent.config_name);
                                                            setAgentConfig(agent);
                                                        }}
                                                        showAll={true}
                                                        buttonText={"All Agents"}
                                                    // ref={agentListRef}

                                                    />
                                                </EuiFlexItem>

                                            </EuiFlexGroup>

                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <SessionListAll course={course} agent={agentConfig}
                                                selectedSession={selectedSession}
                                                setSelectedSession={setSelectedSession}
                                                setChatHistory={setChatHistory}
                                                setAllData={setAllData}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>

                                </EuiFlexItem>
                            </EuiFlexItem>
                            <EuiFlexItem grow={3}>
                                {selectedSession && <ChatPanel data={chatHistory} agentName={selectedSession.config_name} userName={selectedSession.session_owner} />}
                                {!selectedSession &&
                                    <EuiPanel style={{ height: '70vh', overflow: 'auto', maxWidth: "100%" }}>
                                        <EuiEmptyPrompt
                                            iconType="securityAnalyticsApp"
                                            iconColor="default"
                                            title={<h2>Please Select an Session First</h2>}
                                            titleSize="xs"
                                            body={<p>
                                                You must select an session to chat with an agent
                                            </p>}
                                        // actions={actions}
                                        />
                                    </EuiPanel>
                                }
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPage>
    )

};

export default StatisticPage;
