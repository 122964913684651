import React, { useState, useMemo, useContext } from "react";
import {
  EuiPageTemplate,
  EuiButton,
  EuiTabs,
  EuiTab,
  EuiTitle,
  EuiLink,
  EuiImage,
  EuiPanel,
} from "@elastic/eui";
import illustration from "../images/illustration.svg";
import LoginForm from "../forms/LoginForm";
import RegisterForm from "../forms/RegisterForm";
import { Link } from "react-router-dom";
import { UserContext } from "../Context";

const tabs = [
  {
    id: "login--id",
    name: "Login",
    content: <LoginForm />,
  },
  {
    id: "register--id",
    name: "Register",
    content: <RegisterForm />,
  },
];

const HomePage = () => {
  const [selectedTabId, setSelectedTabId] = useState("login--id");
  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => obj.id === selectedTabId)?.content;
  }, [selectedTabId]);
  const { user } = useContext(UserContext);

  const onSelectedTabChanged = (id) => {
    setSelectedTabId(id);
  };

  return (
    <EuiPageTemplate minHeight="0">
      <EuiPageTemplate.EmptyPrompt
        title={<h2>Welcome to MerryQuery!</h2>}
        icon={<EuiImage size="fullWidth" src={illustration} alt="" />}
        color="plain"
        layout="horizontal"
        body={
          <>
            <p>
              Where AI meets learning for a smarter, more personalized
              experience. Our platform brings course-specific answers, guided
              dialogues and reliable source citations right to students, to make
              learning more engaging and impactful. Instructors can enjoy
              real-time insights and flexible tools to support every learner's
              journey.
            </p>
            {!user && (
              <p>
                To access MerryQuery, you need to{" "}
                <Link to="/auth">login or register first</Link>.
              </p>
            )}
            {user && (
              <p>
                Hey {user.username}! You are logged in!
              </p>
            )}
          </>
        }
        // actions={
        //   <EuiPanel hasBorder={false} hasShadow={false}>
        //     <Link to="https://tddschn-mq-quiz.hf.space/">Do Quizzes</Link>
        //     <br />
        //     <Link to="https://tddschn-mq-quiz.hf.space/admin">
        //       Manage Quizzes
        //     </Link>
        //   </EuiPanel>
        // }
        footer={
          <>
            <EuiTitle size="xxs">
              <span>Want to learn more?</span>
            </EuiTitle>{" "}
            <EuiLink
              href="https://github.com/benneigh/MerryQuery"
              target="_blank"
            >
              Read the docs
            </EuiLink>
          </>
        }
      />
    </EuiPageTemplate>
  );
};
export default HomePage;
