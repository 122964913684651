import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import {
    EuiPanel,
    EuiListGroup,
    EuiListGroupItem,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiButtonIcon,
    EuiSpacer,
    EuiAvatar,
    EuiEmptyPrompt,
    EuiTitle,
    EuiTextArea,
    EuiPage,
} from '@elastic/eui';
import SessionList from '../components/SessionList';
import UserText from '../components/UserText';
import AgentText from '../components/AgentText';
import "../css/ChatPage.css";
import { useParams } from 'react-router-dom';
import { UserContext } from '../Context';
import Blocked from '../components/Blocked';
import AgentList from '../components/AgentList';
import CreateSessionPage from './CreateSessionPage';
import { PostOptions } from '../utils/RequestUtils';

const ChatPage = () => {
    const { courseNumber, courseName } = useParams();
    const [agentName, setAgentName] = useState(null);
    const [agentConfig, setAgentConfig] = useState(null);
    const [value, setValue] = useState('');
    const { user } = useContext(UserContext);
    const [isCreateSessionVisible, setIsCreateSessionVisible] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const course = { course_name: courseName, course_number: courseNumber };
    const chatContainerRef = useRef();
    const [deleteFlag, setDeleteFlag] = useState(false);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);


    if (!user) {
        return <Blocked name="Chat Page" />;
    }

    const onChange = (e) => {
        if (isSending) {
            return;
        }
        setValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevents the newline character
            send();
        }
    };

    const send = () => {
        console.log(value);
        if (value.trim() === '') {
            console.error("empty message!");
            return; // Do not send empty messages
        }
        if (!selectedSession) {
            console.error("No session selected");
            return;
        }
        setChatHistory(prevChatHistory => [...prevChatHistory, { text: value, type: "query" }]);
        setValue('');
        setIsSending(true);
        fetch('/api/chat', PostOptions({ session_name: selectedSession.session_name, query: value, course_name: course.course_name }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data, chatHistory);
                // setChatHistory([...chatHistory, {user: user.username, text: value}, {user: agentName, text: data.text}]);
                setChatHistory(prevChatHistory => [...prevChatHistory, { text: data.message, type: "response" }]);
                setIsSending(false);

            })
            .catch(error => {
                console.error('There was an error!', error);
                setIsSending(false);
            });
        // setChatHistory(prevChatHistory => [...prevChatHistory, { text: "resp", type: "response" }]);
        // setIsSending(false);

    };

    const deleteSession = () => {
        if (!selectedSession) {
            console.error("No session selected");
            return;
        }
        fetch('/api/session/remove', PostOptions({ session_name: selectedSession.session_name }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setSelectedSession(null);
                setChatHistory([]);
                setDeleteFlag(!deleteFlag);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    return (
        <EuiPage restrictWidth={"80%"} style={{ overflowY: "scorll", maxHeight: "85vh" }}>
            <EuiFlexGroup style={{ maxWidth: "100%" }}>
                <EuiFlexItem grow={1}>
                    <EuiPanel>
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem justifyContent="center" alignItems="center" >
                            <EuiTitle><h3>{courseNumber + " " + courseName}</h3></EuiTitle>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFlexGroup gutterSize='xs'>
                                <EuiFlexItem>
                                    <AgentList
                                        course={{ course_name: courseName, course_number: courseNumber }}
                                        showCreate={false}
                                        onSelect={(agent) => {
                                            setAgentName(agent.config_name);
                                            setAgentConfig(agent);
                                            setSelectedSession(null);
                                        }}
                                        showAll={false}
                                        buttonText={"All Agents"}
                                    // ref={agentListRef}

                                    />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButtonIcon
                                        iconType="plusInCircle"
                                        aria-label="Create Session"
                                        iconSize="l"
                                        isDisabled={agentName === null || agentName === "All Agents"}
                                        onClick={() => setIsCreateSessionVisible(true)}
                                    // color="secondary"
                                    >Create Session</EuiButtonIcon>
                                    {course && agentConfig && <CreateSessionPage isVisible={isCreateSessionVisible} setIsVisible={setIsCreateSessionVisible} course={course} config_name={agentConfig.config_name} />}
                                </EuiFlexItem>
                            </EuiFlexGroup>

                        </EuiFlexItem>
                        <EuiFlexItem>
                            <SessionList course={course} agent={agentConfig} fresh={[isCreateSessionVisible, deleteFlag]}
                                selectedSession={selectedSession}
                                setSelectedSession={setSelectedSession}
                                setChatHistory={setChatHistory}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    </EuiPanel>

                </EuiFlexItem>
                <EuiFlexItem grow={5} style={{ maxWidth: "100%" }}>
                    {selectedSession && <EuiPanel>
                        <EuiFlexGroup direction='column'>
                            <EuiFlexItem>
                                <EuiPanel style={{ maxHeight: '70vh', minHeight: "70vh", overflowY: 'auto', maxWidth: "100%" }} hasBorder={false} hasShadow={false}>
                                    <div >
                                        {chatHistory.map((item, index) => {
                                            if (item.type === "query") {
                                                return (

                                                    <UserText key={index} name={user.username} text={item.text} />

                                                )
                                            } else {
                                                return (

                                                    <AgentText key={index} text={item.text} agentName={selectedSession.config_name} />


                                                )
                                            }
                                        })}
                                        {isSending && <AgentText text="Thinking" loading={true} />}

                                    </div>
                                    <p ref={chatContainerRef} style={{}}></p>
                                </EuiPanel>

                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFlexGroup>
                                    <EuiFlexItem>
                                        <EuiTextArea
                                            placeholder="Message MerryQuery"
                                            compressed
                                            isClearable
                                            fullWidth
                                            maxHeight="12vh"
                                            style={{ borderRadius: "20px" }}
                                            value={value}
                                            onChange={onChange}
                                            isDisabled={isSending}
                                            isLoading={isSending}
                                            onKeyDown={handleKeyDown} 
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                        <EuiFlexGroup direction='column' gutterSize='none'>
                                            <EuiFlexItem>
                                                <EuiButtonIcon iconType="sortUp" aria-label="Send" iconSize="xl" size="m" onClick={send} isLoading={isSending} isDisabled={value.trim() === '' || isSending}/>
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                <EuiButtonIcon color="danger" iconType="trash" aria-label="Send" iconSize="m" size="m"
                                                    isDisabled={isSending}
                                                    onClick={deleteSession}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiPanel>}
                    {!selectedSession &&
                        <EuiPanel style={{ height: '70vh', overflow: 'auto', maxWidth: "100%" }}>
                            <EuiEmptyPrompt
                                iconType="securityAnalyticsApp"
                                iconColor="default"
                                title={<h2>Please Select an Session First</h2>}
                                titleSize="xs"
                                body={<p>
                                    You must select an session to chat with an agent
                                </p>}
                            // actions={actions}
                            />
                        </EuiPanel>
                    }
                </EuiFlexItem>

            </EuiFlexGroup>
        </EuiPage>
    );
};

export default ChatPage;