// EditProfileModal.js
import React, { useState } from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiTabs,
    EuiTab,
    EuiSpacer,
} from '@elastic/eui';
import AgeEditForm from '../forms/AgeEditForm';
import UsernameEditForm from '../forms/UsernameEditForm';
import FullnameEditForm from '../forms/FullnameEditForm';
import PasswordEditForm from '../forms/PasswordEditForm';

const EditProfilePage = ({ isVisible, setIsVisible }) => {
    const [selectedTab, setSelectedTab] = useState('username');

    const closeModal = () => {
        setIsVisible(false);
    };

    const showModal = () => {
        setIsVisible(true);
    };

    const tabs = [
        {
            id: 'username',
            name: 'Edit Username',
            content: <UsernameEditForm/>,
        },
        {
            id: 'fullname',
            name: 'Edit Full Name',
            content: <FullnameEditForm/>,
        },
        {
            id: 'password',
            name: 'Edit Password',
            content: <PasswordEditForm />,
        },
        {
            id: 'age',
            name: 'Edit Age',
            content: <AgeEditForm/>,
        },
    ];

    return (
        <>
            {isVisible && (
                <EuiModal onClose={closeModal}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Edit Profile</EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiTabs>
                            {tabs.map((tab) => (
                                <EuiTab
                                    key={tab.id}
                                    onClick={() => setSelectedTab(tab.id)}
                                    isSelected={tab.id === selectedTab}
                                >
                                    {tab.name}
                                </EuiTab>
                            ))}
                        </EuiTabs>
                        <EuiSpacer />
                        {tabs.find((tab) => tab.id === selectedTab).content}
                    </EuiModalBody>
                </EuiModal>
            )}
        </>
    );
};

export default EditProfilePage;
