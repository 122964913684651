import React, { useState, useEffect, useContext } from 'react';
import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem, EuiHeaderLogo,EuiButtonEmpty } from '@elastic/eui';
import HeaderUserMenu from './HeaderUserMenu';
import { useLocation, useNavigate, Link, json } from 'react-router-dom';
import { PostOptions } from '../utils/RequestUtils';
import { UserContext } from '../Context';

const PageHeader = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const {user, setUser} = useContext(UserContext);
    useEffect(() => {
        if (user) {
            return;
        }
        fetch('/api/userinfo', PostOptions({}))
            .then(response => response.json())
            .then(data => {
                console.log("hhh")
                console.log(data);
                if (data.username) {
                    sessionStorage.setItem('user', JSON.stringify(data));
                    setUser(data);
                    // setLoginState(true);
                
                } else {
                    if (location.pathname !== '/auth' && location.pathname !== '/') {
                        navigate('/auth');
                    }
                }
                
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        
    }, [user]);

    return (
        <EuiHeader>
            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    <EuiHeaderLogo>MerryQuery</EuiHeaderLogo>
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
            <EuiHeaderSection side="right">
                <EuiHeaderSectionItem>
                    {user && <HeaderUserMenu/>}
                    {!user && 
                        <EuiHeaderSectionItem><Link to="/auth">Login or Register</Link></EuiHeaderSectionItem>
                    }
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
        </EuiHeader>
    )
};

export default PageHeader;