// FileManagerModal.js
import React, { useState, useEffect, useRef } from 'react';
import {
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiResizableContainer,
    EuiFieldSearch,
    EuiListGroup,
    EuiListGroupItem,
    EuiButton,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiPopover,
    EuiTextArea,
    EuiFilePicker,
    EuiText,
    EuiSwitch,
    EuiContextMenuPanel,
    EuiPanel,
    EuiTitle,
    EuiSelectable,
    EuiContextMenuItem,
    EuiInlineEditTitle

} from '@elastic/eui';
import CreateAgentPage from './CreateAgentPage';
import { PostOptions } from '../utils/RequestUtils';
import EditAgentForm from '../forms/EditAgentForm';
import AgentList from '../components/AgentList';

const FileManagePage = ({ course, isModalVisible, setIsModalVisible }) => {
    const [files, setFiles] = useState([]);
    const [document, setDocument] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [documentUploading, setDocumentUploading] = useState(false);
    const [documentUploadError, setDocumentUploadError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    // const [isModalVisible, setIsModalVisible]  = useState(true);
    const [isCourseRelated, setIsCourseRelated] = useState(false);
    const [docDescription, setDocDescription] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isPopoverOpen, setPopover] = useState(false);
    const [agentName, setAgentName] = useState(null);
    const [CreateAgentModalVisible, setCreateAgentModalVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const agentListRef = useRef(null);
    const [agentConfig, setAgentConfig] = useState(null);
    useEffect(() => {
        fetch("/api/agent/get/all", PostOptions({course_name: course.course_name}))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                       
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                const opt = data.data.map((agent) => ({
                    label: agent.config_name,
                    checked: false,
                    ...agent
                }));
                console.log(opt);
                setOptions(opt);
            })
            .catch(error => {

                console.error('There was an error!', error);
                setisLoading(false);
            });
    }, [CreateAgentModalVisible]);

    useEffect(() => {
        if (agentName) {
            fetch("/api/agent/get/one", PostOptions({config_name: agentName, course_name: course.course_name}))
                .then(response => {
                    return response.json().then(text => {
                        if (!response.ok) {
                            alert(text.message);
throw new Error(text.message);
                        }
                        return text;
                    });
                })
                .then(data => {
                    console.log(data);
                    // updtae options using new data
                    setOptions(options.map((opt) => {
                        if (opt.config_name === agentName) {
                            return {
                                ...opt,
                                ...data.data,
                            };
                        }
                        return opt;
                    }));
                    setAgentConfig(data.data);

                    let tmp_files = [];
                    for (let i = 0; i < data.data.non_required_inclusion_documents.length; i++) {
                        tmp_files.push({
                            id: i + 1,
                            name: data.data.non_required_inclusion_documents[i].name,
                            is_related: false,
                            description: data.data.non_required_inclusion_documents[i].description,
                        });
                    }
                    for (let i = 0; i < data.data.required_inclusion_documents.length; i++) {
                        tmp_files.push({
                            id: i + 1,
                            name: data.data.required_inclusion_documents[i].name,
                            is_related: true,
                            description: data.data.required_inclusion_documents[i].description,
                        });
                    }
                    console.log(tmp_files);
                    setFiles(tmp_files);

                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }, [agentName]);

    const handleSearchChange = (e) => {
        // Handle search logic here
        // console.log('Search query:', e.target.value);
        setSearchQuery(e.target.value);
    };

    const handleFileSelect = (file) => {
        setSelectedFile(file);
        // console.log('Selected file:', file.description);
        setIsUploading(false);
    };

    const handleDeleteFile = (delFile) => {

        fetch("/api/docs/remove/one", PostOptions({config_name: agentName, document_name: delFile.name, course_name: course.course_name}))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                setFiles(files.filter((file) => file.id !== delFile.id));
                if (selectedFile && selectedFile.id === delFile.id) {
                    setSelectedFile(null);
                }
                console.log(data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        
    };

    const handleFileUpload = () => {
        setIsUploading(true);
        setSelectedFile(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!document || !document.name) {
            setDocumentUploadError('Please select a file to upload.');
            return;
        }
        setDocumentUploadError(null);
        setDocumentUploading(true);
        const formData = new FormData();
        formData.append('file', document);
            formData.append('config_name', agentName);
            formData.append('document_name', document.name);
            formData.append('required_inclusion', isCourseRelated);
            formData.append('doc_description', docDescription);
            formData.append('course_name', course.course_name);

            try {
                const response = await fetch("/api/upload", {
                    method: 'POST',
                    body: formData,
                });
                
                const data = await response.json();
                setDocumentUploading(false);
                if (!response.ok) {
                    setDocumentUploadError(data.message);
                    
                    // alert the response
                    alert(data.message);
                    return;
                }
                console.log(data);
                setFiles([...files, {
                    id: files.length + 1,
                    name: document.name,
                    description: docDescription,
                    is_related: isCourseRelated,
                }]);
                handleFileSelect({
                    id: files.length + 1,
                    name: document.name,
                    description: docDescription,
                    is_related: isCourseRelated,
                    
                });
                setDocument(null);
                setDocDescription('');
                setIsCourseRelated(false);
                setIsUploading(false);
            
            } catch (error) {
                console.error('There was an error!', error);
                if (error.response && error.response.data) {
                    // alert the error if there is one
                    alert(error.response.data.message);
                }
            }
        };
        //['config_name', 'document_name', 'required_inclusion', 'doc_description', 'course_name']


    // };

    const onNameChange = (oldName, newName) => {
        setAgentName(newName);
        
        // console.log(agentListRef.current.onNameChange(oldName, newName));
        agentListRef.current.onNameChange(oldName, newName);
    };

    const renderFileList = () => (
        <EuiListGroup bordered flush>
            {files.filter((file) => (
                !searchQuery || file.name.toLowerCase().includes(searchQuery.toLowerCase())
            ))
                .map((file, index) => (
                <EuiListGroupItem
                    key={index}
                    label={file.name}
                    iconType={"document"}
                    onClick={() => handleFileSelect(file)}
                    extraAction={{
                        color: 'danger',
                        onClick: () => handleDeleteFile(file),
                        iconType: 'trash',
                        iconAriaLabel: 'Delete',
                        alwaysShow: true,
                    }}
                />
            ))}
        </EuiListGroup>
    );

    const onDocumentUpdate = () => {
        setDocumentUploading(true);
        fetch("/api/docs/update/one", PostOptions({
            config_name: agentName,
            document_name: selectedFile.name,
            required_inclusion: selectedFile.is_related,
            doc_description: selectedFile.description,
            course_name: course.course_name,
        }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setDocumentUploadError(text.message);
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setDocumentUploading(false);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setDocumentUploading(false);
            });
    }


    const renderRightPane = () => {
        if (isUploading) {
            return (
                <EuiForm component="form" onSubmit={handleSubmit} error={documentUploadError}>
                    <EuiFormRow label="Upload File" fullWidth>
                        <EuiFilePicker fullWidth onChange={(files) => {
                            setDocument(files.length ? files[0] : null);
                        }} isLoading={documentUploading} isDisabled={documentUploading}/>
                    </EuiFormRow>
                    <EuiFormRow label="File Description" fullWidth>
                        <EuiTextArea fullWidth value={docDescription} onChange={(e) => 
                            setDocDescription(e.target.value)
                        }/>
                    </EuiFormRow>
                    <EuiFormRow label="Is this a document related to course information (e.g., syllabus)?" fullWidth display="columnCompressedSwitch">
                        <EuiSwitch
                            label={isCourseRelated ? 'Yes' : 'No'}
                            checked={isCourseRelated}
                            onChange={(e) => setIsCourseRelated(e.target.checked)}
                        />
                    </EuiFormRow>
                    <EuiText size="s" color="subdued">
                        <p>Note: These files will be exempt from data controls to ensure students receive straightforward, clear responses.</p>
                    </EuiText>
                    <EuiButton type="submit" fill fullWidth isLoading={documentUploading} isDisabled={documentUploading}>
                        Submit
                    </EuiButton>
                </EuiForm>
            );
        }

        if (selectedFile) {
            return (
                <EuiForm component="form" onSubmit={onDocumentUpdate}>
                    <EuiFormRow label="File Content" fullWidth>
                        <EuiTextArea value={selectedFile.description} fullWidth onChange={(e) => 
                            {
                                setSelectedFile({ ...selectedFile, description: e.target.value });
                                setFiles(files.map((file) => {
                                    if (file.id === selectedFile.id) {
                                        return { ...file, description: e.target.value };
                                    }
                                    return file;
                                }));
                            }
                            
                        }/>
                    </EuiFormRow>
                    <EuiFormRow label="Is this a document related to course information (e.g., syllabus)?" fullWidth display="columnCompressedSwitch">
                        <EuiSwitch
                            label={isCourseRelated ? 'Yes' : 'No'}
                            checked={selectedFile.is_related}
                            onChange={(e) => {
                                setSelectedFile({ ...selectedFile, is_related: e.target.checked });
                                setFiles(files.map((file) => {
                                    if (file.id === selectedFile.id) {
                                        return { ...file, is_related: e.target.checked };
                                    }
                                    return file;
                                }));
                            }}
                        />
                    </EuiFormRow>
                    <EuiButton type="submit" fill fullWidth>
                        Update
                    </EuiButton>
                </EuiForm>
            );
        }

        return <EuiText><p>Select a file to edit or upload a new file.</p></EuiText>;
    };

    const onButtonClick = () => {
        setPopover(!isPopoverOpen);
    };

    const closePopover = () => {
        setPopover(false);
    };

    const button = (
        <EuiButton
        fullWidth
            size="s"
            iconType="arrowDown"
            iconSide="right"
            onClick={onButtonClick}
        >
            {agentName || "Please Select an Agent First"}
        </EuiButton>
    );

    return (
        <>
            {isModalVisible && <EuiModal onClose={() => setIsModalVisible(false)} style={{ minWidth: "50vw", minHeight: "50vh" }} >
                <EuiModalHeader>
                    <EuiModalHeaderTitle>File Manager</EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiModalBody>
                            <EuiResizableContainer direction="horizontal" style={{ height: "50vh" }}>
                                {(EuiResizablePanel, EuiResizableButton) => (
                                    <>
                                        <EuiResizablePanel initialSize={30} minSize="20%">
                                            <EuiFlexGroup direction="column" gutterSize="none" style={{ height: '100%' }} justifyContent="spaceBetween">
                                                <EuiFlexItem grow={false}>
                                                    <EuiFlexGroup direction="column" gutterSize="xs" style={{ height: '100%' }}>
                                                <EuiFlexItem grow={false}>
                                                    
                                                    <AgentList 
                                                        course={course}
                                                        showCreate={true}
                                                        onSelect={(agent) => {
                                                            setAgentName(agent.config_name);
                                                            setAgentConfig(agent);
                                                            setSelectedFile(null);
                                                        }}
                                                        buttonText={"Please Select OR Create an Agent First"}
                                                        ref={agentListRef}

                                                    />
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    {agentConfig && <EditAgentForm config={agentConfig} course={course} onNameChange={onNameChange}/>}
                                                </EuiFlexItem>
                                                        <EuiFlexItem grow={false}>
                                                            <EuiFieldSearch
                                                            compressed
                                                                placeholder="Search files"
                                                                fullWidth
                                                                onChange={handleSearchChange}
                                                            />
                                                            <EuiSpacer size="m" />
                                                        </EuiFlexItem>
                                                        <EuiFlexItem grow={false} style={{ overflowY: 'auto' }}>
                                                            {renderFileList()}
                                                        </EuiFlexItem>
                                                    </EuiFlexGroup>
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    <EuiSpacer size="m" />
                                                    <EuiButton fullWidth onClick={handleFileUpload}>
                                                        Upload File
                                                    </EuiButton>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiResizablePanel>
                                        <EuiResizableButton />
                                        <EuiResizablePanel initialSize={70} minSize="50%">
                                            {renderRightPane()}
                                        </EuiResizablePanel>
                                    </>
                                )}
                            </EuiResizableContainer>
                    
                </EuiModalBody>
            </EuiModal>
            }
            {/* {CreateAgentModalVisible && <CreateAgentPage isVisible={CreateAgentModalVisible} setIsVisible={setCreateAgentModalVisible} course={course} />} */}
        </>
    );
};

export default FileManagePage;
