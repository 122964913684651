// EditProfileModal.js
import React, { useState } from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiTabs,
    EuiTab,
    EuiSpacer,
} from '@elastic/eui';
import CreateSesssionForm from '../forms/CreateSessionForm';

const CreateSessionPage = ({ isVisible, setIsVisible, course, config_name }) => {
    

    const closeModal = () => {
        setIsVisible(false);
    };

    const showModal = () => {
        setIsVisible(true);
    };

    

    return (
        <>
            {isVisible && (
                <EuiModal onClose={closeModal}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Create Session</EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <CreateSesssionForm course={course} config_name={config_name} onSuccess={() => setIsVisible(false)}/>
                    </EuiModalBody>
                </EuiModal>
            )}
        </>
    );
};

export default CreateSessionPage;
