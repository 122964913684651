import React, { useState, useMemo } from 'react';
import {
    EuiPageTemplate,
    EuiButton,
    EuiTabs,
    EuiTab,
    EuiTitle,
    EuiLink,
    EuiImage,
    EuiPanel,
} from '@elastic/eui';
import illustration from '../images/illustration.svg';
import LoginForm from '../forms/LoginForm';
import RegisterForm from '../forms/RegisterForm';


const tabs = [
    {
        id: "login--id",
        name: "Login",
        content: (<LoginForm />)
    },
    {
        id: "register--id",
        name: "Register",
        content: (<RegisterForm />)
    }
];

const LoginPage = () => {
    const [selectedTabId, setSelectedTabId] = useState('login--id');
    const selectedTabContent = useMemo(() => {
        return tabs.find((obj) => obj.id === selectedTabId)?.content;
    }, [selectedTabId]);
    const onSelectedTabChanged = (id) => {
        setSelectedTabId(id);
    };

    
    return (
        <EuiPageTemplate minHeight="0">
            <EuiPageTemplate.EmptyPrompt
                title={<h2>Welcome to MerryQuery!</h2>}
                icon={<EuiImage size="fullWidth" src={illustration} alt="" />}
                color="plain"
                layout="horizontal"
                body={
                    <>
                        <p>
                            "MerryQuery" is an AI-powered educational assistant that utilizes retrieval-augmented geeration (RAG)
                            to provide students with tailored responses based on course materials.
                        </p>
                        <p>
                            To access MerryQuery, you need to login or register first.
                        </p>
                    </>
                }
                actions={
                    <EuiPanel hasBorder={false} hasShadow={false}>
                    <EuiTabs>
                        {tabs.map((tab, index) => (
                            <EuiTab
                                key={index}
                                href={tab.href}
                                onClick={() => onSelectedTabChanged(tab.id)}
                                isSelected={tab.id === selectedTabId}
                            >
                                {tab.name}
                            </EuiTab>
                        ))}
                    </EuiTabs>
                    {selectedTabContent}
                    </EuiPanel>
                }
                footer={
                    <>
                        <EuiTitle size="xxs">
                            <span>Want to learn more?</span>
                        </EuiTitle>{' '}
                        <EuiLink href="https://github.com/benneigh/MerryQuery" target="_blank">
                            Read the docs
                        </EuiLink>
                    </>
                }
            />
        </EuiPageTemplate>
    );
};
export default LoginPage;