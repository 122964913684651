import React, { useState, useContext } from 'react';
import {
    EuiPanel,
    EuiListGroup,
    EuiListGroupItem,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiButtonIcon,
    EuiSpacer,
    EuiAvatar,
    EuiTitle,
    EuiTextArea,
    EuiPage,
} from '@elastic/eui';
import SessionList from './SessionList';
import UserText from './UserText';
import AgentText from './AgentText';
import { useParams } from 'react-router-dom';
import { UserContext } from '../Context';
import Blocked from './Blocked';


const ChatPanel = ({data, agentName, userName}) => {
    console.log(data);
    console.log(agentName);
    return (      
        <EuiPanel style={{ maxHeight: '70vh', minHeight: '70vh', overflowY: 'auto', maxWidth: "100%" }} hasBorder={false} hasShadow={false} className='no-scroll'>
            {
                data.map((item, index) => {
                    if (item.type === 'query') {
                        return (<UserText key={index} name={userName} text={item.text} />);
                    } else {
                        return (<AgentText key={index} text={item.text} agentName={agentName}/>);
                    }
                })
            }
            
        </EuiPanel>
                
    );
};

export default ChatPanel;