// UserForm.js
import React, { useState, useContext } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiFieldPassword,
    EuiSelect,
    EuiFieldNumber,
    EuiButton,
    EuiText,
} from '@elastic/eui';
import { PostOptions } from '../utils/RequestUtils';
import { UserContext } from '../Context';
import { useNavigate } from 'react-router-dom';

const UserForm = () => {
    const [form, setForm] = useState({
        username: '',
        password: '',
    });
    const { user, setUser } = useContext(UserContext);
    const [isLoading, setisLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    if (user) {
        navigate('/');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log('Form data:', form);
        setHasError(false);
        setisLoading(true);
        fetch("/api/login", PostOptions(form))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setHasError(true);
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                setUser(data);
                navigate('/');
            })
            .catch(error => {

                console.error('There was an error!', error);
                setHasError(true);
                setisLoading(false);
            });
    };

    return (
        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={hasError} error={hasError ? "Invalid username or password" : ""}>
            <EuiFormRow label="Username">
                <EuiFieldText
                    name="username"
                    value={form.username}
                    onChange={handleChange}
                />
            </EuiFormRow>

            

            <EuiFormRow label="Password" >
                <EuiFieldPassword
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                />
            </EuiFormRow>


            <EuiButton type="submit" fill isLoading={isLoading}>
                Login
            </EuiButton>
        </EuiForm>
    );
};

export default UserForm;
