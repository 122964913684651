import React, {useState} from 'react';
import '@elastic/eui/dist/eui_theme_light.css';
import { EuiProvider, EuiPage, EuiPageHeader, EuiPageSidebar, EuiPageBody, EuiPageSection } from '@elastic/eui';

import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import HeaderUserMenu from './components/HeaderUserMenu';
import PageHeader from './components/PageHeader';
import PageNav from './components/PageNav';
import RegisterForm from './forms/RegisterForm';
import LoginForm from './forms/LoginForm';
import LoginPage from './pages/LoginPage';
import CourseListPage from './pages/CourseListPage';
import FileManagePage from './pages/FileManagePage';
import ChatPage from './pages/ChatPage';
import HomePage from './pages/HomePage';
import { UserProvider } from './Context';
import StatisticPage from './pages/StatisticPage';

const App = () => {
  // const [loginState, setLoginState] = useState(false);
  
  return (
    <EuiProvider colorMode="light">
      <UserProvider>
      <Router>
        <EuiPage paddingSize="none" >
          <EuiPageSidebar paddingSize="none" sticky minWidth={"0"}>
            <PageNav />
          </EuiPageSidebar>
          <EuiPageBody paddingSize="none">
            <EuiPageHeader paddingSize="none">
              <EuiPageSection>
                <PageHeader/>
              </EuiPageSection>
            </EuiPageHeader>
            <EuiPageSection>
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route path="/auth" element={<LoginPage />} />
                <Route path="/courses" element={<CourseListPage />} />
                <Route path="/sessions/:courseNumber/:courseName" element={<ChatPage />} />
                  <Route path="/statistic/:courseNumber/:courseName" element={<StatisticPage />} />
              </Routes>
              {/* <CourseListPage/> */}
              {/* <LoginPage /> */}
              {/* <p>Page content goes here</p> */}
              {/* <RegisterForm />
          <LoginForm /> */}
            </EuiPageSection>
          </EuiPageBody>
        </EuiPage>
      </Router>
      </UserProvider>
    </EuiProvider>
  );
};
export default App;