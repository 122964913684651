import React, { useState } from 'react';
import {
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButton,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiSwitch,
    EuiAvatar,
    EuiIcon,
    EuiSpacer,
    EuiFieldSearch,
    EuiCopy,
    EuiButtonIcon,
    EuiIconTip,
    EuiPanel
} from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import CourseAddForm from '../forms/CourseAddForm';

const CourseAddPage = ({ isModalVisible, setIsModalVisible }) => {
    if (!isModalVisible) {
        return null;
    }

    return (
        <EuiModal onClose={() => setIsModalVisible(false)}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>Add Course</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <CourseAddForm onSuccess={() => setIsModalVisible(false)} />
            </EuiModalBody>
        </EuiModal>
    );
};

export default CourseAddPage;
