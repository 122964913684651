import React, { useState } from "react";
import {
  EuiPanel,
  EuiListGroup,
  EuiListGroupItem,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiAvatar,
  EuiSpacer,
} from "@elastic/eui";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";

const UserText = ({ text, name }) => {
  return (
    <>
      <EuiFlexGroup justifyContent="flexEnd" style={{ maxWidth: "100%" }}>
        <EuiFlexItem grow={false} style={{ maxWidth: "80%" }}>
          <EuiPanel color="subdued" style={{ borderRadius: "20px",
          background: "linear-gradient(62deg, #dbe9f8 0%, #ecdff5 100%)", 
          }}>
            <EuiText size="s" style={{ wordBreak: "break-word", whiteSpace: "pre-wrap", color: "#1f1f1f", fontWeight: "400"} }>
            {text}
            </EuiText>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiAvatar size="s" name={name} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
    </>
  );
};

export default UserText;
