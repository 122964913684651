import React from 'react';
import { EuiEmptyPrompt, EuiPage } from '@elastic/eui';

const Blocked = ({name}) => {
    const title = name ? `Unable to Load ${name}` : "Unable to Load This Page";
    return (
        <EuiPage restrictWidth={"80%"}>
            {/* <EuiEmptyPrompt
                    icon={<EuiLoadingLogo logo="logoKibana" size="xl" />}
                    title={<h2>Loading</h2>}
                /> */}
            <EuiEmptyPrompt
                iconType="error"
                color="danger"
                title={<h2>{title}</h2>}
                body={
                    <p>
                        There was an error loading the {name}. Please try again later.
                        Remember, you need to be logged in to view the {name}.
                    </p>
                }
            />
        </EuiPage>
    );
};

export default Blocked;