import React, { useState, useEffect } from 'react';
import {
    Chart,
    LineSeries,
    Settings,
    Axis,
    timeFormatter,
    DataGenerator,
    niceTimeFormatter,
    Position,
} from '@elastic/charts';
import '@elastic/charts/dist/theme_light.css';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiDatePickerRange,
    EuiDatePicker,
    EuiPanel,
    EuiTitle,
} from '@elastic/eui';
import moment from 'moment';

const StatisticChart = ({ data }) => {
    const [stData, setStData] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
    const [endDate, setEndDate] = useState(moment());

    useEffect(() => {
        let tmp = {};
        data.forEach(d => {
            const day = moment(d.date_and_time).format('YYYY-MM-DD');
            const agent = d.config_name;
            if (!tmp[agent]) {
                tmp[agent] = {};
                tmp[agent][day] = 1;
            } else {
                if (tmp[agent][day]) {
                    tmp[agent][day] += 1;
                }
                else {
                    tmp[agent][day] = 1;
                }
            }
        });
        console.log(tmp);
            let tmp_data = [];
            for (const agent in tmp) {
                for (const day in tmp[agent]) {
                    tmp_data.push({date: day, value: tmp[agent][day], agent: agent});
                }
            }
            setStData(tmp_data);

        }, [data]);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    // Filter data based on selected date range
    const filteredData = stData.filter(d => moment(d.date).isBetween(startDate, endDate, null, '[]'));

    return (
        <EuiPanel>
            <EuiFlexGroup gutterSize="m" alignItems="center" direction='column'>
                <EuiFlexItem grow={false}>
                    <EuiDatePickerRange
                        startDateControl={
                            <EuiDatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                isInvalid={startDate > endDate}
                                aria-label="Start date"
                            />
                        }
                        endDateControl={
                            <EuiDatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                isInvalid={startDate > endDate}
                                aria-label="End date"
                            />
                        }
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup gutterSize="m">
                <EuiFlexItem>
                    <Chart size={{ height: "15vh" }}>
                        <Settings
                            showLegend={true}
                            legendPosition="right"
                            showLegendExtra={false}
                        />
                        <LineSeries
                            id="bars"
                            name="0"
                            data={filteredData}
                            xAccessor={'date'}
                            yAccessors={['value']}
                            splitSeriesAccessors={['agent']}

                        />
                        <Axis
                            id="bottom-axis"
                            position="bottom"
                            gridLine={{ visible: true }}
                        />
                        <Axis
                            id="left-axis"
                            position="left"
                            gridLine={{ visible: true }}
                            tickFormat={(d) => Number(d).toFixed(2)}
                        />
                    </Chart>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPanel>
    );
};

export default StatisticChart;
