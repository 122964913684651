import React, { useState } from "react";
import {
  EuiPanel,
  EuiListGroup,
  EuiListGroupItem,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiAvatar,
  EuiSkeletonText,
  EuiSpacer,
} from "@elastic/eui";
import "katex/dist/katex.min.css";
import MarkdownRenderer from "./MarkdownRenderer";

const AgentText = ({ text, loading, agentName }) => {
  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiAvatar size="s" name={agentName ? agentName : "Agent"} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel color="subdued" style={{ borderRadius: "20px" }}>
            {loading && (
              <EuiSkeletonText
                lines={3}
                size="m"
                isLoading={true}
                contentAriaLabel="Thinking"
              >
                <EuiText size="s">
                  <p>Thinking</p>
                </EuiText>
              </EuiSkeletonText>
            )}
            {!loading && ( 
              <EuiText size="s">
                <MarkdownRenderer>
                  {text}
                </MarkdownRenderer>
              </EuiText>
            )}
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
    </>
  );
};

export default AgentText;
