import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { dracula } from "react-syntax-highlighter/dist/cjs/styles/prism";

const MarkdownRenderer = ({ children }) => {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");

          return !inline && match ? (
            <SyntaxHighlighter
              //   style={dracula}
              PreTag="pre"
              language={match[1]}
              {...props}
              customStyle={{
                background: "#f5f5f5", // Customize background if needed
                borderRadius: "5px",  // Rounded corners
              }}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        pre({ children }) {
          return (
            <pre
              style={{
                margin: "0 0 0", // Custom margin for <pre>
                padding: "0.5em",  // Custom padding for <pre>
                overflowX: "auto", // Horizontal scroll if content is too wide
              }}
            >
              {children}
            </pre>
          );
        },
        p: ({ children, ...props }) => (
          <p style={{ margin: "0.6em 0", lineHeight: "1.5em" }} {...props}>
            {children}
          </p>
        ),
        ul: ({ children, ...props }) => (
          <ul style={{ margin: "0.6em 0", paddingLeft: "1.5em" }} {...props}>
            {children}
          </ul>
        )
      }}
    >
      {children}
    </Markdown>
  );
};

export default MarkdownRenderer;
