import React, { useState, useEffect } from 'react';
import {
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButton,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiSwitch,
    EuiAvatar,
    EuiIcon,
    EuiSpacer,
    EuiFieldSearch,
    EuiCopy,
    EuiButtonIcon,
    EuiIconTip,
    EuiPanel
} from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import { PostOptions } from '../utils/RequestUtils';

const RosterPage = ({ isModalVisible, setIsModalVisible, course }) => {

   
    // console.log(course.course_members);
    const [search, setSearch] = useState('');
    const [view, setView] = useState('student');
    const [invitationCode, setInvitationCode] = useState(course.invitation_code || '');
    const [enableInvitation, setEnableInvitation] = useState(course.allow_invites);
    const [roster, setRoster] = useState([]);
    const [copySuccess, setCopySuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setRoster(course.course_members);
    }, [course]);


    const handleSearchChange = (e) => setSearch(e.target.value);

    const handleViewChange = () => setView(view === 'student' ? 'teacher' : 'student');

    const handleDelete = (username) => {
        setIsLoading(true);
        fetch("/api/course/members/delete", PostOptions({
            course_name: course.course_name,
            course_number: course.course_number,
            username: username,
        }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setIsLoading(false);
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setRoster(roster.filter(student => student.username !== username));
                setIsLoading(false);
                setErrorMessage(null);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('There was an error!', error);
                // setErrorMessage(error);
            });
    };

    const generateCode = () => {
        const code = Math.floor(100000 + Math.random() * 900000).toString();
        return code;
    };

    const handleInvitation = () => {
        let code = '';
        if (!enableInvitation) {
            if (!invitationCode) {
                code = generateCode();
            }
        }
        setIsLoading(true);
        fetch("/api/course/invitation/edit", PostOptions({
            course_name: course.course_name,
            course_number: course.course_number,
            invitation_code: code,
            allow_invites: !enableInvitation
        }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setIsLoading(false);
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setInvitationCode(code);
                setEnableInvitation(!enableInvitation);
                setIsLoading(false);
                setErrorMessage(null);
            })
            .catch(error => {
                setIsLoading(false);
                // setErrorMessage(error)
                console.error('There was an error!', error);
            });
    };
    const handleInvitationCode = () => {
       if (!enableInvitation) {
        return;
       }
       setIsLoading(true);
       const code = generateCode();

        fetch("/api/course/invitation/edit", PostOptions({
            course_name: course.course_name,
            course_number: course.course_number,
            invitation_code: code,
            allow_invites: enableInvitation
        }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setIsLoading(false);
                        alert(text.message);
throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setInvitationCode(code);
                setIsLoading(false);
                setErrorMessage(null);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setIsLoading(false);
                // setErrorMessage(error);
            });
    }

    const handleCopy = (copy) => {
        copy();
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1000);
    };

    if (!isModalVisible) {
        return null;
    }

    return (
        <EuiModal onClose={() => setIsModalVisible(false)}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>Roster</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiFlexGroup gutterSize="s" alignItems="center">
                    <EuiFlexItem>
                        <EuiFieldSearch
                            placeholder="Search..."
                            value={search}
                            onChange={handleSearchChange}
                            fullWidth
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiSwitch
                            label={view === 'student' ? 'Students' : 'Teachers'}
                            checked={view === 'student'}
                            onChange={handleViewChange}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size="m" />
                <EuiPanel style={{maxHeight: "45vh", overflow: "auto"}} hasBorder={false} hasShadow={false}>
                {
                roster.filter((student)  => student.role === view).filter((student) => (
                    !search || student.username.toLowerCase().includes(search.toLowerCase()) || student.full_name.toLowerCase().includes(search.toLowerCase())
                )).map(student => (
                    <EuiFlexGroup alignItems="center" key={student.username}>
                        <EuiFlexItem grow={false}>
                            <EuiAvatar size="m" name={student.username} />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiText>{student.username}</EuiText>
                            <EuiText size="s" color="subdued">{student.full_name}</EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                iconType="trash"
                                color="danger"
                                onClick={() => handleDelete(student.username)}
                                aria-label="Delete student"
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                ))}
                </EuiPanel>
                <EuiSpacer size="m" />
                <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiSwitch
                            label="Enable Invitation Code"
                            checked={enableInvitation}
                            onChange={handleInvitation}
                            isLoading={isLoading}
                        />
                    </EuiFlexItem>
                    
                        <EuiFlexItem>
                            <EuiFieldText
                            isDisabled={!enableInvitation}
                            isLoading={isLoading}
                                readOnly
                            value={!enableInvitation ? "---" : invitationCode}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                iconType="refresh"
                                isDisabled={!enableInvitation}
                                color="primary"
                                onClick={handleInvitationCode}
                                aria-label="Generate new code"
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiCopy textToCopy={invitationCode}>
                                {(copy) => (
                                    <EuiButtonIcon
                                        iconType="copy"
                                        isDisabled={!enableInvitation}
                                        color="primary"
                                        onClick={() => handleCopy(copy)}
                                        aria-label="Copy code"
                                    />
                                )}
                            </EuiCopy>
                        </EuiFlexItem>
                        
                </EuiFlexGroup>
                {copySuccess && (
                    <EuiText color="success">
                        <p>Code copied to clipboard!</p>
                    </EuiText>
                )}
                {errorMessage && (
                    <EuiText color="danger">
                        <p>{errorMessage}</p>
                    </EuiText>
                )}
            </EuiModalBody>
        </EuiModal>
    );
};

export default RosterPage;
