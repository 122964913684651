// import React, { useState } from 'react';
// import {
//     EuiCollapsibleNav,
//     EuiKeyPadMenu,
//     EuiKeyPadMenuItem,
//     EuiButtonIcon,
//     EuiSpacer,
//     EuiText,
//     EuiCode,
//     EuiIcon,
//     EuiFlexGroup,
//     EuiFlexItem,
// } from '@elastic/eui';


// SidebarNav.js
import React, { useState, useContext } from 'react';
import {
    EuiCollapsibleNav,
    EuiFlexGroup,
    EuiFlexItem,
    EuiListGroupItem,
    EuiIcon,
    EuiText,
    EuiSpacer,
    EuiKeyPadMenuItem,
    EuiKeyPadMenu,
    EuiCollapsibleNavGroup
} from '@elastic/eui';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../Context';

const PageNav = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const location = useLocation();
    const {user} = useContext(UserContext);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <nav aria-label="Nav title">
            <EuiCollapsibleNav isOpen isDocked size={isNavOpen ? "8%" : "3%"} ownFocus={false} >
                <EuiFlexGroup direction='column' gutterSize='none' justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiCollapsibleNavGroup>
                            <EuiKeyPadMenu style={{ display: 'block', width: '100%' }}>
                                <Link to="/">
                                    <EuiKeyPadMenuItem
                                        label={isNavOpen ? "Home" : ""}
                                        isSelected={location.pathname === '/'}
                                        // onClick={() => alert('Math Homework Help: Algebra')}
                                        style={{ width: '100%', textAlign: 'center' }}
                                    >

                                        <EuiIcon type="home" size={isNavOpen ? "xl" : "m"} />

                                    </EuiKeyPadMenuItem>
                                </Link>
                                <Link to="/courses">
                                    <EuiKeyPadMenuItem
                                        label={isNavOpen ? "Course" : ""}
                                        isSelected={location.pathname === '/courses'}
                                        // onClick={() => alert('History Exam: Key Events')}
                                        style={{ width: '100%', textAlign: 'center' }}
                                    >

                                        <EuiIcon type="article" size={isNavOpen ? "xl" : "m"} />

                                    </EuiKeyPadMenuItem>
                                </Link>
                                    <EuiKeyPadMenuItem
                                    disabled={!location.pathname.startsWith('/sessions')}
                                        label={isNavOpen ? "Sessions" : ""}
                                        isSelected={location.pathname.startsWith('/sessions')}
                                        // onClick={() => alert('History Exam: Key Events')}
                                        style={{ width: '100%', textAlign: 'center' }}
                                    >

                                        <EuiIcon type="newChat" size={isNavOpen ? "xl" : "m"} />

                                    </EuiKeyPadMenuItem>
                                {user?.role === "teacher" &&<EuiKeyPadMenuItem
                                    disabled={!location.pathname.startsWith('/statistic')}
                                    label={isNavOpen ? "Statistic" : ""}
                                    isSelected={location.pathname.startsWith('/statistic')}
                                    style={{ width: '100%', textAlign: 'center' }}
                                >

                                    <EuiIcon type="heatmap" size={isNavOpen ? "xl" : "m"} />

                                </EuiKeyPadMenuItem>}
                            </EuiKeyPadMenu>
                        </EuiCollapsibleNavGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>

                        <EuiIcon
                            type={isNavOpen ? "arrowStart" : "arrowEnd"}
                            size="l"
                            style={{ width: '100%', textAlign: 'center', marginBottom: "20%" }}
                            onClick={toggleNav}
                        />

                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiCollapsibleNav>

        </nav>

    );
};

export default PageNav;
